// 全角文字チェック
export function isIncludedDoubleByteChar(str: string) {
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    // Shift_JIS: 0x0 ～ 0x80, 0xa0 , 0xa1 ～ 0xdf , 0xfd ～ 0xff
    // Unicode : 0x0 ～ 0x80, 0xf8f0, 0xff61 ～ 0xff9f, 0xf8f1 ～ 0xf8f3
    if (
      (char >= 0x0 && char < 0x81) ||
      char == 0xf8f0 ||
      (char >= 0xff61 && char < 0xffa0) ||
      (char >= 0xf8f1 && char < 0xf8f4)
    ) {
      // 半角文字
    } else {
      // 全角文字
      return true;
    }
  }
  return false;
}

export const zeroPadding = (num: number, len: number) => (Array(len).join("0") + num).slice(-len);

export const maskPassword = (p: string): string =>
  (p &&
    Array.from(p)
      .map(() => "*")
      .join("")) ||
  "";

export const verifyEmail = (email: string, suffix?: boolean): string => {
  if (!email) return "メールアドレスが設定されてません";
  if (isIncludedDoubleByteChar(email)) {
    return "メールアドレスに全角文字を含めないでください";
  }
  const regexWithoutSuffix =
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
  const regexWithSuffix =
    /^(("[\w-\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
  if (!email.match(suffix ? regexWithSuffix : regexWithoutSuffix)) {
    return "メールアドレスのフォーマットが誤っています";
  }
  return "";
};

// 半角英数字をそれぞれ1種類以上含む6文字以上100文字以下の正規表現
export const verifyPassword = (password: string): string => {
  if (!password) return "パスワードが設定されていません";
  if (isIncludedDoubleByteChar(password)) {
    return "パスワードに全角文字を含めないでください";
  }
  if (!password.match(/^(?=.*?[a-z])(?=.*?\d)[a-z\d]{6,100}$/i)) {
    return "パスワードは、6文字以上でかつ、半角英数字をそれぞれ1種類以上含めてください";
  }
  return "";
};

export const verifyPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return "電話番号が設定されていません";
  if (!phoneNumber.match(/^0\d{9,10}$/i)) {
    return "電話番号のフォーマットが謝っています";
  }
  return "";
};

// CSVエスケープ文字を適応する
export const escapeForCSV = (original: string | null | undefined, ignoreNewLine: boolean = false): string => {
  if (!original) {
    return "";
  }
  return ignoreNewLine
    ? `${original.replace(/"/g, '""').replace(/,/g, ",").replace(/\n/g, " ")}`
    : `${original.replace(/"/g, '""').replace(/,/g, ",")}`;
};

export const ellipsis = (str: string, size: number) => {
  if (!str) {
    return "";
  }
  let t = "";
  let s = size * 2;
  const e = "...";
  for (let i = 0; i < str.length; i++) {
    const _string = str.charAt(i);
    if (/[a-zA-Z0-9\s]/.test(_string)) {
      s -= 1;
    } else s -= 2;
    t = t + _string;
    if (s <= 0) {
      t += e;
      break;
    }
  }
  return t;
};

export const formatSnsTitle = (text: string) => {
  if (text === "all") return "すべて";
  if (text === "tiktok") return "TikTok";
  if (text === "youtube") return "YouTube";
  if (text === "instagram") return "Instagram";
  if (text === "instagram-pro") return "Instagram(プロアカウント)";
  if (text === "twitter") return "X(旧Twitter)";
  if (text === "google") return "Google";
  if (text === "tabelog") return "食べログ";
  if (text === "trip-adviser") return "TripAdviser（準備中）";
  return text;
};
export const verifySnsAccounts = (accountType: string, accountUrl: string): string => {
  if (!accountUrl) return "";
  const REGEX = /^(https?|ftp)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)$/;
  if (!accountUrl.match(REGEX)) {
    return "URLの形式が無効です";
  }
  if (accountType === "youtube") {
    if (!accountUrl.includes("youtu")) return `URLに${accountType}が含まれていません`;
    else return "";
  } else {
    if (!accountUrl.includes(accountType)) {
      return `URLに${accountType}が含まれていません`;
    }
  }
  return "";
};

/**
 * ファイル名を抽出して名前衝突回避の為にsuffixにUNIX_TIMEを挿入する
 * @param baseName
 * @param extension
 */
export const generateFileName = (baseName: string, extension: string = "") => {
  // 名前衝突を防ぐ為にunix_timeをファイル名に付与する
  const unix_time = new Date().getTime().toString();

  // パスからファイル名を抽出
  const fileName = baseName.split("/").pop() || baseName;
  // ファイル名から拡張子を分離
  const fileStems = fileName.split(".");

  // ファイル名に拡張子がついていなかった場合
  if (fileStems.length === 1) {
    return `${fileStems[0]}_${unix_time}.${extension}`;
  }

  const fileStem = fileStems.slice(0, -1).join(".");
  const ext = extension || fileStems.pop() || "";
  return `${fileStem}_${unix_time}.${ext}`;
};
